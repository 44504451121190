import React, { useEffect, useState } from 'react';
import Isotope from 'isotope-layout';
import axios from 'axios';

const Portfolio = () => {
    const [portfolios, setPortfolios] = useState([]);

    useEffect(() => {
        axios.get('https://freelancerpanna.tech/api/getportfolios.php')
            .then(response => {
                setPortfolios(response.data.data);
            })
            .catch(error => {
                console.error('There was an error fetching the portfolios!', error);
            });
    }, []);

    useEffect(() => {
        const grid = document.querySelector('.portfolio-box');
        const iso = new Isotope(grid, {
            itemSelector: '.portfolio-item',
            layoutMode: 'fitRows',
            percentPosition: true,
            masonry: {
                columnWidth: '.portfolio-sizer',
                gutter: '.gutter-sizer',
            },
        });

        const handleFilterClick = (e) => {
            e.preventDefault();
            const filterValue = e.target.getAttribute('data-filter');
            iso.arrange({ filter: filterValue });
            document.querySelectorAll('.portfolio-filter a').forEach((el) => el.classList.remove('active'));
            e.target.classList.add('active');
        };

        document.querySelectorAll('.portfolio-filter a').forEach((el) => {
            el.addEventListener('click', handleFilterClick);
        });

        return () => {
            iso.destroy();
        };
    }, [portfolios]);

    return (
        <div className="row">
            <div className="col-12">
                <div data-wow-delay="0.4s" className="wow fadeInUp mb-5">
                    <ul className="portfolio-filter d-flex justify-content-center align-items-center">
                        <li><a className="active rounded-end-0" data-filter="*">All</a></li>
                        <li><a className="rounded-end-0 rounded-start-0" data-filter=".Web_Design">Web Design</a></li>
                        <li><a className="rounded-start-0" data-filter=".Graphic_Design">Graphic Design</a></li>
                        {/* <li><a className="rounded-end-0 rounded-start-0" data-filter=".Graphic_Design">Graphic Design</a></li> */}
                        {/* <li><a className="rounded-start-0" data-filter=".Web_Development">Web Development</a></li> */}
                    </ul>
                </div>
                <div className="row portfolio-box wow fadeInUp" data-wow-delay="0.3s">
                    {portfolios.map(portfolio => (
                        <div
                            className={`col-lg-4 col-md-6 portfolio-item ${portfolio.category}`}
                            key={portfolio.id}
                        >
                            <div className="box">
                                <div className="image-box">
                                    <img
                                        src={`${portfolio.image}`}
                                        alt={portfolio.title}
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="content-box">
                                    <div className="sm-box">
                                        <h3 className="portfolio-title">{portfolio.title}</h3>
                                        <p>{portfolio.details}</p>
                                        <a href={portfolio.url} className="portfolio-link" target="_blank" rel="noopener noreferrer">
                                            <i className="fa-solid fa-arrow-down-long"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Portfolio;
