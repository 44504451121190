import React, { useState } from 'react';
import axios from 'axios';
import validator from 'validator'; // Import the validator library for validation
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitization

const ContactForm = () => {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    selectOption: '',
    comments: '',
  });

  // Function to sanitize inputs using DOMPurify
  const sanitizeInput = (input) => {
    return DOMPurify.sanitize(input);
  };

  // Regular expression to block unwanted SQL-like characters
  const disallowedPattern = /[\^\$\{\}\(\)\.\\\\/]/;

  // Handle input change and update state
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to validate inputs before form submission
  const validateInputs = () => {
    const { fullName, email, phoneNumber, selectOption, comments } = formData;

    if (disallowedPattern.test(fullName)) {
      return 'Full Name contains invalid characters.';
    }
    if (disallowedPattern.test(comments)) {
      return 'Comments contain invalid characters.';
    }
    if (!validator.isEmail(email)) {
      return 'Please enter a valid email address.';
    }
    if (!validator.isMobilePhone(phoneNumber, 'any')) {
      return 'Please enter a valid phone number.';
    }
    if (validator.isEmpty(fullName)) {
      return 'Full Name cannot be empty.';
    }
    if (validator.isEmpty(selectOption)) {
      return 'Please select an option.';
    }
    if (validator.isEmpty(comments)) {
      return 'Comments cannot be empty.';
    }

    return ''; // No validation errors
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Sanitize form data before sending
    const sanitizedData = {
      fullName: sanitizeInput(formData.fullName),
      email: sanitizeInput(formData.email),
      phoneNumber: sanitizeInput(formData.phoneNumber),
      selectOption: sanitizeInput(formData.selectOption),
      comments: sanitizeInput(formData.comments),
    };

    // Validate inputs
    const validationError = validateInputs();
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    setErrorMessage(''); // Clear any previous error messages

    // Submit sanitized and validated form data to the server
    axios.post('https://freelancerpanna.tech/api/submit_form.php', sanitizedData)
      .then(response => {
        console.log('Data submitted successfully:', response.data);
        setFormData({
          fullName: '',
          email: '',
          phoneNumber: '',
          selectOption: '',
          comments: '',
        });
        setSuccessMessage('Your message was submitted successfully!');

        // Hide the success message after 30 seconds
        setTimeout(() => {
          setSuccessMessage('');
        }, 30000);
      })
      .catch(error => {
        console.error('There was an error submitting the form!', error);
        setErrorMessage('There was an error submitting the form. Please try again later.');
      });
  };

  return (
    <form className="row" onSubmit={handleSubmit}>
      {successMessage && (
        <div className="col-12 mb-3">
          <div className="alert alert-success" role="alert">
            {successMessage}
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="col-12 mb-3">
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        </div>
      )}
      <div className="col-md-6">
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="floatingInputName"
            placeholder="Full Name"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            aria-label="Full Name"
            required
          />
          <label htmlFor="floatingInputName">Full Name</label>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-floating mb-3">
          <input
            type="email"
            className="form-control"
            id="floatingInputEmail"
            placeholder="name@example.com"
            name="email"
            value={formData.email}
            onChange={handleChange}
            aria-label="Email address"
            required
          />
          <label htmlFor="floatingInputEmail">Email address</label>
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-floating mb-3">
          <input
            type="tel"
            className="form-control"
            id="floatingInputPhone"
            placeholder="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            aria-label="Phone Number"
            pattern="^\d{10,15}$" // Added pattern attribute to validate phone numbers
            title="Please enter a valid phone number with 10 to 15 digits."
            required
          />
          <label htmlFor="floatingInputPhone">Phone Number</label>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="form-floating mb-3">
          <select
            className="form-select"
            id="floatingSelect"
            name="selectOption"
            value={formData.selectOption}
            onChange={handleChange}
            aria-label="Select menu"
            required
          >
            <option value="" disabled>Open this select menu</option>
            <option value="Web Design">Web Design</option>
            <option value="Graphic Design">Graphic Design</option>
            <option value="Web Development">Web Development</option>
            <option value="SEO">SEO</option>
            <option value="Support">Support</option>
          </select>
          <label htmlFor="floatingSelect">Works with selects</label>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="form-floating mb-3">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            id="floatingTextarea2"
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            style={{ height: '100px' }}
            aria-label="Comments"
            required
          ></textarea>
          <label htmlFor="floatingTextarea2">Comments</label>
        </div>
      </div>
      <div className="col-auto">
        <button type="submit" className="web-btn2 mb-3">Send</button>
      </div>
    </form>
  );
};

export default ContactForm;
