import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import Portfolio from '../section/Portfolio';
import CounterBox from '../section/CounterBox';
import ContactForm from '../section/ContactForm';


function Home() {

    useEffect(() => {
        new WOW.WOW().init();
    }, []);

    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        if (window.pageYOffset > 300) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      };
  
      // Attach event listener
      window.addEventListener('scroll', handleScroll);
  
      // Clean up event listener on component unmount
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);


    
  return (
    <>

    <header className={` header-part section-bgcolor p-2 ${isSticky ? 'sticky-header' : ''}`} >
        <div className="container">
        <nav className="navbar navbar-expand-lg ">
                <a className="navbar-brand" href="https://freelancerpanna.tech/">
                    
                <img src="/img/logo.png" className="img-fluid" alt="fp logo" />


                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="cust_icon"><i className="fas fa-bars"></i></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav ms-auto">

                        <li className="nav-item">
                            <a className="nav-link" href="#about">ABOUT</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#skills">SKILLS</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#experience">EXPERIENCE</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#reviews">REVIEWS</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#pricing">PRICING</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#portfolio">PORTFOLIO</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#contact">CONTACT</a>
                        </li>
                    </ul>

                </div>
            </nav>
        </div>
    </header>


<main id="content" className="site-main mt-3 ">
    <div>
        <section className=" banner-part ">
            <div className="short-info-banner comm_padd">
                <div className="">

                    <section className="hero-section d-flex align-items-center" id="intro">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="hero-content-box mb-lg-0 mb-5">

                                        <span className="hero-sub-title">
                                            I am Panna </span>

                                        <h1 className="hero-title">Web & Graphic Designer + Web Developer</h1>


                                        <p className="lead mt-2">I break down complex user experinece problems to create integritiy focussed solutions that connect billions of people</p>

                                        <div className="mt-3 button-box d-lg-flex d-md-flex align-items-center mt-sm-4">

                                            <a href="/img/cv-mdhasiburrahmanpanna.pdf" className="px-4 web-btn-circle">
                                                Download CV <i className="fa-solid fa-download"></i> </a>


                                            <ul className="mx-lg-3 mx-md-3 ul-reset social-icons d-flex align-items-center mt-lg-0 mt-md-0 mt-5 ">
                                                <li>
                                                    <a href="https://www.freelancer.com/u/mdpanna1" target="_blank" className="web-btn-circle">
                                                    <i className="fa-solid fa-f"></i> </a>
                                                </li>
                                                
                                                <li>
                                                    <a href="https://www.upwork.com/freelancers/~01c08d0ff29b510574" target="_blank" className="web-btn-circle">
                                                        <i className="fa-brands fa-upwork"></i> </a>
                                                </li>

                                                <li>
                                                    <a href="https://www.behance.net/mdpanna1" target="_blank" className="web-btn-circle">
                                                    <i className="fa-brands fa-behance"></i> </a>
                                                </li>



                                                <li>
                                                    <a href="https://www.linkedin.com/in/mdpanna1/" target="_blank" className=" web-btn-circle">
                                                        <i aria-hidden="true" className="fab fa-linkedin-in"></i> </a>
                                                </li>
                                                <li>
                                                    <a href="https://wa.me/8801879378411" target="_blank" className=" web-btn-circle">
                                                    <i className="fa-brands fa-whatsapp"></i> </a>
                                                </li>
                                                <li>
                                                    <a href="https://github.com/mdpanna" target="_blank" className=" web-btn-circle">
                                                        <i aria-hidden="true" className="fab fa-github"></i> </a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">

                                    <div className="hero-image-box text-center">
                                        <img src="/img/panna.jpg" className="img-fluid" alt="ThemeJunction" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
            <div className="container mt-5" id="about">

                <div className="content-box">
                    <p>I’m a Web Designer and Developer who has been creating designs since 2018. I’ve worked on platforms like Freelancer.com 
                        and Upwork.com, where I’ve built a strong portfolio and received great feedback from clients. I enjoy trying new things 
                        and solving challenges. I’m certified in Computer Engineering from Saic Institute in Bangladesh. Design is not just my 
                        job but my passion for both now and the future. I work full-time as a freelancer, always aiming to provide the best service.



                    </p>
                </div>
            </div>
            <div className="container comm_padd ">
                <div className="overall-self-ratio">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <CounterBox count={200} text={"Project Completed"} />
                                <CounterBox count={30} text={"Happy Clients"} />
                                <CounterBox count={6} text={"Years of Experience"} />
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section>

        
        <section className="professional-part comm_padd" id="skills">
            <div className="container">
                <div className="title-box text-center mb-5 ">
                    <h2>Professional Skills
                    </h2>
                </div>
                <div className="content-box">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="box">
                                <h5>Website Design</h5>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" style={{"width":"95%"}} aria-valuenow="95" aria-valuemin="0" aria-valuemax="95"></div>
                                </div>

                                <h5>WordPress</h5>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" style={{"width":"75%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="75"></div>
                                </div>

                                <h5>Graphic Design</h5>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" style={{"width":"85%"}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="85"></div>
                                </div>

                                <h5>Web Development</h5>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" style={{"width":"80%"}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="80"></div>
                                </div>




                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="box">
                                <h5>HTML/CSS</h5>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" style={{"width":" 90%"}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="90"></div>
                                </div>

                                <h5>Adobe Illustrator</h5>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" style={{"width":" 85%"}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="85"></div>
                                </div>

                                <h5>PHP/Mysqli</h5>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" style={{"width":" 80%"}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="80"></div>
                                </div>

                                <h5>Server Management</h5>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" style={{"width":" 83%"}} aria-valuenow="83" aria-valuemin="0" aria-valuemax="83"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className=" service-part comm_padd section-bgcolor">
            <div className="container">
                <div className="title-box text-center mb-5 ">
                    <h2>
                        My Quality Services </h2>
                    <p>We put your ideas and thus your wishes in the form of a unique web project that inspires you and you customers.</p>

                </div>
                <div className="content-box bg-white p-4 border">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="box">
                                <i className="fas fa-paint-brush"></i>
                                <h4>WEB DESIGN</h4>
                                <h5>HTML/CSS, Jquery Animation</h5>
                                <hr />
                                <p>Creating visually appealing and user-friendly websites using HTML/CSS and jQuery animations to enhance user experience.</p>
                                <p></p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="box"> <i className="fas fa-paint-brush"></i>

                                <h4>WEB DEVELOPMENT</h4>
                                <h5>PHP, Mysql, Ajax, Jquery</h5>
                                <hr />
                                <p>Building dynamic and interactive websites using PHP, MySQL, Ajax, and jQuery to meet diverse business needs.</p>

                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="box"> <i className="fas fa-paint-brush"></i>

                                <h4>GRAPHIC DESIGN</h4>
                                <h5>Adobe Illustrator</h5>
                                <hr />
                                <p>Designing compelling graphics and visual elements with Adobe Illustrator to effectively communicate your brand message.</p>

                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="box"> <i className="fas fa-paint-brush"></i>

                                <h4>WordPress Website</h4>
                                <h5>Page Builder, Woocommerce</h5>
                                <hr />
                                <p>Developing custom WordPress websites with Page Builder and WooCommerce to provide a robust and scalable online presence.</p>

                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="box"> <i className="fas fa-paint-brush"></i>

                                <h4>MARKETING</h4>
                                <h5>SEO</h5>
                                <hr />
                                <p>Optimizing websites for search engines to improve visibility and drive organic traffic with effective SEO strategies.</p>

                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="box"> <i className="fas fa-paint-brush"></i>

                                <h4>SUPPORT</h4>
                                <h5>Hosting Management, Cpanel</h5>
                                <hr />
                                <p>Managing hosting services and cPanel to ensure your website runs smoothly and efficiently, with minimal downtime.</p>
                            </div>
                        </div>





                    </div>
                </div>
            </div>
        </section>






        <section className="recent-work-part comm_padd" id="portfolio">
            <div className="container">

                <div className="title-box text-center mb-5 ">
                    <h2>My Recent Works </h2>
                </div>

                <div className="content-box">
                    <div className="portfolios-box">

                        <Portfolio />

                    </div>
                </div>
            </div>
        </section>




        <section className="experience-education-part comm_padd section-bgcolor " id="experience">
            <div className="container">

                <div className="row">

                    <div className="col-md-6">
                        <div className="">
                            <div className="title-box  mb-4 ">
                                <h2><i className="fa-solid fa-medal"></i> My Experience</h2>
                            </div>
                            <div className="resume-widget">
                                <div className="rounded-4 bg-white p-3 px-4  resume-item wow fadeInLeft mb-4" data-wow-delay="0.3s">
                                    <h4 className="time web-color2 fw-bold">2022-2024</h4>
                                    <h3 className="resume-title m-2 mx-0 fw-bold">Financial Analyst</h3>
                                    <span className="institute fw-medium">SUMMIT ASSOCIATES</span>
                                </div>
                                <div className="rounded-4 bg-white p-3 px-4  resume-item wow fadeInLeft mb-4" data-wow-delay="0.3s">
                                    <h4 className="time web-color2 fw-bold">2021-2023</h4>
                                    <h3 className="resume-title m-2 mx-0 fw-bold">Website Designer & Developer</h3>
                                    <span className="institute fw-medium">Convastay Luxury Serviced Apartments , Chennai(India)</span>
                                </div>
                                <div className="rounded-4 bg-white p-3 px-4  resume-item wow fadeInLeft mb-4" data-wow-delay="0.3s">
                                    <h4 className="time web-color2 fw-bold">2019-2023</h4>
                                    <h3 className="resume-title m-2 mx-0 fw-bold">Website Designer & Developer</h3>
                                    <span className="institute fw-medium">Angson Service Apartment, Chennai(India)</span>
                                </div>
                                <div className="rounded-4 bg-white p-3 px-4  resume-item wow fadeInLeft mb-4" data-wow-delay="0.3s">
                                    <h4 className="time web-color2 fw-bold">2022-2023</h4>
                                    <h3 className="resume-title m-2 mx-0 fw-bold">Graphic Designer</h3>
                                    <span className="institute fw-medium">Victoria Supermarket, Toronto(Canada) </span>
                                </div>
                                <div className="rounded-4 bg-white p-3 px-4  resume-item wow fadeInLeft mb-4" data-wow-delay="0.3s">
                                    <h4 className="time web-color2 fw-bold">2019-2021</h4>
                                    <h3 className="resume-title m-2 mx-0 fw-bold">Graphic Designer</h3>
                                    <span className="institute fw-medium">Ideascalegov, USA</span>
                                </div>
                              
                                {/* <div className="rounded-4 bg-white p-3 px-4  resume-item wow fadeInLeft mb-4" data-wow-delay="0.3s">
                                    <h4 className="time web-color2 fw-bold">2020-2021</h4>
                                    <h3 className="resume-title m-2 mx-0 fw-bold">Graphic Designer</h3>
                                    <span className="institute fw-medium">Amoete, France </span>
                                </div> */}


                            </div>


                        </div>
                    </div>

                    <div className=" col-md-6">
                        <div className="">
                            <div className="title-box  mb-4 ">
                                <h2><i className="fa-solid fa-graduation-cap"></i> My Education</h2>
                            </div>
                            <div className="resume-widget">
                                {/* <div className="rounded-4 bg-white p-3 px-4  resume-item wow fadeInLeft mb-4" data-wow-delay="0.3s">
                                    <h4 className="time web-color2 fw-bold">2022 - Present</h4>
                                    <h3 className="resume-title m-2 mx-0 fw-bold">Computer Science and Engineering</h3>
                                    <span className="institute fw-medium">Bangladesh University of Business and Technology (BUBT) </span>
                                </div> */}
                                <div className="rounded-4 bg-white p-3 px-4  resume-item wow fadeInLeft mb-4" data-wow-delay="0.3s">
                                    <h4 className="time web-color2 fw-bold">2017 - 2022</h4>
                                    <h3 className="resume-title m-2 mx-0 fw-bold">Diploma in Computer Engineering</h3>
                                    <span className="institute fw-medium">Saic Institute of Management and Technology </span>
                                </div>
                                <div className="rounded-4 bg-white p-3 px-4 resume-item wow fadeInLeft mb-4" data-wow-delay="0.3s">
                                    <h4 className="time web-color2 fw-bold">2015 - 2017</h4>
                                    <h3 className="resume-title m-2 mx-0 fw-bold">Secondary School Certificate in Science</h3>
                                    <span className="institute fw-medium">Bangladesh-German Technical Training Centre (BGTTC) </span>
                                </div>
                                <div className="rounded-4 bg-white p-3 px-4  resume-item wow fadeInLeft mb-4" data-wow-delay="0.3s">
                                    <h4 className="time web-color2 fw-bold">2021</h4>
                                    <h3 className="resume-title m-2 mx-0 fw-bold">Certificate of Graphic Design</h3>
                                    <span className="institute fw-medium">CodersTrust Bangladesh</span>
                                </div>
                                <div className="rounded-4 bg-white p-3 px-4  resume-item wow fadeInLeft mb-4" data-wow-delay="0.3s">
                                    <h4 className="time web-color2 fw-bold">2020</h4>
                                    <h3 className="resume-title m-2 mx-0 fw-bold">Certificate of Web Development</h3>
                                    <span className="institute fw-medium">CodersTrust Bangladesh</span>
                                </div>
                                <div className="rounded-4 bg-white p-3 px-4  resume-item wow fadeInLeft mb-4" data-wow-delay="0.3s">
                                    <h4 className="time web-color2 fw-bold">2019</h4>
                                    <h3 className="resume-title m-2 mx-0 fw-bold">Certificate of Web Design</h3>
                                    <span className="institute fw-medium">UY LAB </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="interest-part comm_padd ">
            <div className="container">
                <div className="title-box text-center mb-5 ">
                    <h2>My Interests</h2>
                </div>
                <div className="content-box">
                    <div className="box">
                        <label> <i className="fas fa-paint-brush"></i>
                        </label>

                        <label> <i className="fas fa-code"></i>
                        </label>


                        <label><i className="fas fa-bicycle"></i></label>


                        <label> <i className="fas fa-video"></i></label>


                        <label><i className="fas fa-camera"></i></label>
                    </div>
                </div>
            </div>
        </section>

        <section className=" pricing-part comm_padd section-bgcolor" id="pricing">
            <div className="container">
                <div className="title-box text-center mb-4 ">
                    <h2>Pricing</h2>
                    <p>I am pretty flexible and ready to move forward to my clients with additional requirements or with<br /> changing existing requirements. For any additional packages please contact me directly.</p>

                </div>
                <div className="content-box">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="main-box">
                                        <div className="box">
                                            <h4>Graphic Design</h4>
                                            <label>Select Your Item</label>
                                        </div>
                                        <div className="price_tag-box"><i className="fas fa-dollar-sign"></i>
                                            <h3>$15<span>/project</span></h3>
                                        </div>
                                        <div className="box">
                                            <ul>
                                                <li>Logo Design</li>
                                                <li>Business Card Design</li>
                                                <li>Flyer Design</li>
                                                <li>CV Design</li>
                                                <li>Google/Apple Store Image Design</li>
                                                <li>Brochure Design</li>
                                            </ul>
                                            <div className="btn-box">
                                                <a href="#contact">START NOW <i className="fas fa-shopping-cart"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>







                                <div className="col-lg-4 col-md-6">
                                    <div className="main-box">
                                        <div className="box">
                                            <h4>Web Design</h4>
                                            <label>Select Your Item</label>
                                        </div>
                                        <div className="price_tag-box"><i className="fas fa-dollar-sign"></i>
                                            <h3>$40<span>/project</span></h3>
                                        </div>
                                        <div className="box">
                                            <ul>
                                                <li>PSD TO HTML</li>
                                                <li>Website Design/Redesign</li>
                                                <li>Landing Page Design</li>
                                                <li>Full Website Design(+60$)</li>
                                                <li>Portfolio Design</li>
                                                <li>WordPress Website Design</li>
                                                <li>Html/Css/Jquery Bug Fix</li>
                                            </ul>
                                            <div className="btn-box">
                                                <a href="#contact">START NOW <i className="fas fa-shopping-cart"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>






                                <div className="col-lg-4 col-md-6">
                                    <div className="main-box">
                                        <div className="box">
                                            <h4>Web Development</h4>
                                            <label>Select Your Item</label>
                                        </div>
                                        <div className="price_tag-box"><i className="fas fa-dollar-sign"></i>
                                            <h3>$80<span>/project</span></h3>
                                        </div>
                                        <div className="box">
                                            <ul>
                                                <li>Php Bug Fix</li>
                                                <li>Website Development</li>
                                                <li>Database Problem Fix/Connection</li>
                                                <li>Mysqli, Mysql, Ajax, Crud</li>
                                                <li>Laravel Framework(+30$)</li>
                                            </ul>
                                            <div className="btn-box">
                                                <a href="#contact">START NOW <i className="fas fa-shopping-cart"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>



        <section className="my-reviews-part comm_padd" id="reviews">
            <div className="container">
                <div className="title-box text-center mb-5 ">
                    <h2>Reviews</h2>
                    <p>Where Every Pixel Tells a Story</p>
                </div>
                <div className="row reviews-widget">
                    <div className="col-lg-3 col-md-6 reviews-item wow fadeInUp" data-wow-delay="0.3s">
                        <img src={`https://freelancerpanna.tech/reviews_img/img.jpg`} alt="reviews" className='mb-3 img-thumbnail img-fluid' />
                    </div>
                    <div className="col-lg-3 col-md-6 reviews-item wow fadeInUp" data-wow-delay="0.3s">
                        <img src={`https://freelancerpanna.tech/reviews_img/img2.jpg`} alt="reviews" className='mb-3 img-thumbnail img-fluid' />
                    </div>
                    <div className="col-lg-3 col-md-6 reviews-item wow fadeInUp" data-wow-delay="0.3s">
                        <img src={`https://freelancerpanna.tech/reviews_img/img3.jpg`} alt="reviews" className='mb-3 img-thumbnail img-fluid' />
                    </div>
                    <div className="col-lg-3 col-md-6 reviews-item wow fadeInUp" data-wow-delay="0.3s">
                        <img src={`https://freelancerpanna.tech/reviews_img/img4.jpg`} alt="reviews" className='mb-3 img-thumbnail img-fluid' />
                    </div>
                    <div className="col-lg-3 col-md-6 reviews-item wow fadeInUp" data-wow-delay="0.3s">
                        <img src={`https://freelancerpanna.tech/reviews_img/img6.jpg`} alt="reviews" className='mb-3 img-thumbnail img-fluid' />
                    </div>
                    <div className="col-lg-3 col-md-6 reviews-item wow fadeInUp" data-wow-delay="0.3s">
                        <img src={`https://freelancerpanna.tech/reviews_img/img7.jpg`} alt="reviews" className='mb-3 img-thumbnail img-fluid' />
                    </div>
                    <div className="col-lg-3 col-md-6 reviews-item wow fadeInUp" data-wow-delay="0.3s">
                        <img src={`https://freelancerpanna.tech/reviews_img/img8.png`} alt="reviews" className='mb-3 img-thumbnail img-fluid' />
                    </div>
                    <div className="col-lg-3 col-md-6 reviews-item wow fadeInUp" data-wow-delay="0.3s">
                        <img src={`https://freelancerpanna.tech/reviews_img/img9.png`} alt="reviews" className='img-fluid' />
                    </div>
                </div>
            </div>
        </section>


        <section className=" contact-part section-bgcolor comm_padd" id="contact">
            <div className=" container">
                <div className="row">
                    <div className="col-lg-6 ">
                        <div className="bg-white p-5 rounded">
                            <div className="title-box  mb-4 ">
                                <h2 data-wow-delay="0.3s" className="section-title wow fadeInUp">
                                    Let’s work together! </h2>
                                <p data-wow-delay="0.4s" className=" wow fadeInUp text-secondary">I design and code beautifully simple things and i love what i do. Just simple like that!</p>
                            </div>
                            <div className="gerold-contact-form wow fadeInUp" data-wow-delay="0.5s">
                                <div className="form-horizontal">

                                    <ContactForm />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" col-lg-6">
                        <div className="contact-info  h-100">
                            <div className="contact-info-list d-lg-flex mt-lg-0 mt-3  justify-content-center align-content-center flex-wrap  h-100">
                                <ul className="  ">

                                    <li className="d-flex align-items-center wow fadeInRight" data-wow-delay="0.2s">
                                        <div className="icon-box">
                                            <i className="fa-solid fa-phone"></i>
                                        </div>
                                        <div className="text-box">
                                            <p>Phone</p>
                                            <a href="tel:+8801879378411">(+880) 1879378411</a>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center wow fadeInRight" data-wow-delay="0.3s">
                                        <div className="icon-box">
                                            <i className="fa-solid fa-envelope"></i>
                                        </div>
                                        <div className="text-box">
                                            <p>Email</p>
                                            <a href="mailto:mdpanna600@mail.com">mdpanna600@gmail.com</a>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center wow fadeInRight" data-wow-delay="0.4s">
                                        <div className="icon-box">
                                            <i className="fa-solid fa-location-dot"></i>
                                        </div>
                                        <div className="text-box">
                                            <p>Address</p>
                                            <a href="#">Dhaka, Bangladesh</a>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</main>

<footer className="footer-area  web-bgcolor2 text-white p-3">
    <div className="container">
        <div className="row">
            <div className="col text-center">
                <div className="footer-logo-box m-3">
                    <a href="https://freelancerpanna.tech/">
                        <img src="/img/flogo.png" className="img-fluid " style={{"width":"150px"}} alt="logo" />
                    </a>
                </div>

                <div className="footer-menu text-center ">
                   
                    <ul id="menu-footer-menu" className="d-lg-flex d-lg-flex align-content-center justify-content-center d-block mb-3 fw-bold text-white">

                        <li className="nav-item">
                            <a className="nav-link mb-md-0 m-2" href="#about">ABOUT</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link mb-md-0 m-2" href="#skills">SKILLS</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link mb-md-0 m-2" href="#experience">EXPERIENCE</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link mb-md-0 m-2" href="#reviews">REVIEWS</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link mb-md-0 m-2" href="#pricing">PRICING</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link mb-md-0 m-2" href="#portfolio">PORTFOLIO</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link mb-md-0 m-2" href="#contact">CONTACT</a>
                        </li>
                        </ul>

                </div>

                <div className="copy-text">
                    <p>© 2024 All Rights Reserved by <a className="web-color1" href="https://freelancerpanna.tech/" target="_blank">Freelancerpanna</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>

    
    </>
  )
}

export default Home