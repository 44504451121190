import React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './component/primary/Home';
import Portfolioup from './component/admin/Portfolioup';


function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/admin/uploadportfolio/" element={<Portfolioup />} /> */}
        {/* Add more routes here as needed */}
      </Routes>
    </Router>
    </>
  );
}

export default App;
