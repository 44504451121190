import React, { useEffect, useState } from 'react';

const CounterBox = ({ count, text }) => {
    const [currentCount, setCurrentCount] = useState(0);

    useEffect(() => {
        const updateCount = () => {
            const increment = count / 200;
            setCurrentCount(prevCount => {
                const newCount = prevCount + increment;
                return newCount < count ? newCount : count;
            });
        };

        const interval = setInterval(() => {
            updateCount();
        }, 10);

        return () => clearInterval(interval);
    }, [count]);

    return (
        <div className="col-md-4 col-6">
            <div className="box">
                <div className="">
                    <div className="d-flex flex-column flex-sm-row justify-content-center  align-items-center">
                        <div className="number">
                            <span></span>
                            <span className="odometer">{Math.round(currentCount)}</span>
                            <span>{count > 50 && "+"}</span>
                        </div>
                        <div className="text">{text}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CounterBox;
